@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .rich-html {
        font-weight: 400;
    }
    .rich-html h1 {
        font-size: 1.875rem;
        line-height: 2.5rem;
        font-weight: 600;
    }
    .rich-html h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
    }
    .rich-html h3 {
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: 500;
    }
}

.rich-html h1, .rich-html h2, .rich-html h3, .rich-html h4  {
  margin-bottom: 1rem;
}

.rich-html p, .rich-html img {
  margin-bottom: 1.5rem;
}

.rich-html p:last-child {
  margin-bottom: 0;
}

.rich-html div:last-child p:last-child {
  margin-bottom: 1.5rem;
}

.rich-html ol,
.rich-html ul {
  margin: 0.5rem 0 1.5rem 0;
  padding-left: 1.5rem;
}
.rich-html ul {
  list-style: disc;
}
.rich-html ol {
  list-style-type: list;
}
.rich-html ol li {
  counter-increment: step-counter;
}
.rich-html a {
    color: #3758F9;
    text-decoration: underline;
    font-weight: 500;
}